.Container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 20vh;
  overflow: hidden;
}

.Img {
  height: 100%;
  width: 33.33%;
  object-fit: fill;
  border: 1px solid #fff;
}

/* const divStyle = {
  width: '100vw',
  height: '30vh',
  backgroundImage: `url(${imageUrl})`,
  backgroundSize: '100% 100%',
  backgroundPosition: 'center',
}; */