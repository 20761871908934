.Container {
    height: 50%;
    width: 100%;
}

.Name {
    margin: auto;
    font-size: 1.7vw;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 10px;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
}

@media (max-aspect-ratio: 7/5){
    .Name{
        font-size: 2.5vw;
        letter-spacing: 7px;
    }
}

@media (max-aspect-ratio: 1/1){
    .Name{
        font-size: 4vw;
        letter-spacing: 4px;
    }
}

@media (max-aspect-ratio: 5/7){
    .Name{
        font-size: 6.5vw;
        letter-spacing: 6px;
        font-weight: 200;
    }
}